import moment from "moment";
import bouncer from "@/helpers/bouncer";
import catalogService from "@/modules/core/service/catalog.service";
import paymentService from "@/modules/core/service/payment.service";
import {
    dateFormats,
    emits,
    fileUploads,
    numberFormat,
    paymentDetailStates,
    typeNotification,
    yearRangeDateInitMyEnrollment
} from "@/helpers/consts";

export default {
    mixins: [bouncer],
    name: "SidebarPayments",
    props: {
        enrollment: Object,
        country: Object,
        edit: Boolean
    },
    data() {
        return {
            allKey: paymentDetailStates.ALl,
            pendingKey: paymentDetailStates.PENDING,
            approvedKey: paymentDetailStates.APPROVED,
            denyKey: paymentDetailStates.DENY,
            cheKey: paymentDetailStates.CHECK,
            paymentModel: {
                observation: null,
                date_deposit: null,
                elaboration_date: null,
                check_number: null,
                type_id: null,
                value_paid: null,
                date_payment: null,
                nro_doc: null,
                documents: [],
                id:null
            },
            selectedPaymentType: null,
            payments: [],
            paymentTypes: [],
            submitted: false,
            loading: false,
            paymentDateFormat: dateFormats.DDMMYYYY,
            paymentYearRange: yearRangeDateInitMyEnrollment,
        };
    },
    emits: [emits.CANCEL],
    mounted() {
        if (this.canAccess("cobranzas_cuentas")) {
            this.paymentModel.date_payment = new Date();
            this.payments = this.enrollment.payments;
        }
    },
    methods: {
        onSelectedFiles() {
            if (this.paymentModel.documents === undefined) {
                this.paymentModel.documents = [];
            }
            if (this.$refs.filesInput) {
                for (let file of this.$refs.filesInput.files) {
                    const reader = new FileReader();
                    this.loading = true;
                    reader.onload = (e) => {
                        let document = {
                            document: e.target.result,
                            name: file.name,
                        }
                        if (file.size <= fileUploads.MAX_FILE_SIZE) {
                            this.paymentModel.documents.push(document);
                        } else {
                            const summary = "Error";
                            const detail = "El archivo: " + file.name + ' ' + 'sobrepasa el tamaño permitido.';
                            this.notification(typeNotification.ERROR, summary, detail);
                        }
                        this.loading = false;
                        this.$refs.filesInput.value = '';
                    };
                    reader.readAsDataURL(file);
                }
            }
        },
        deleteFile(index,idDB) {
            if(idDB!==null){
                this.loading = true;
                return new Promise((resolve, reject) => {
                    paymentService
                        .deleteDocumentPayment(idDB)
                        .then(response => {
                            this.payments = response.data;
                            const summary = "Atención";
                            const detail = "Documento de respaldo de Pago eliminado correctamente";
                            this.notification(typeNotification.SUCCESS, summary, detail);
                            resolve(response.data);
                            this.paymentModel.documents.splice(index, 1);
                            this.loading = false;
                        })
                        .catch(error => {
                            this.processErrorDeleteService(error);
                            reject();
                            this.loading = false;
                        });
                });
            }else{
                this.paymentModel.documents.splice(index, 1);
            }


        },
        notification(severity, summary, detail) {
            this.$toast.add({
                severity: severity,
                summary: summary,
                detail: detail,
                life: this.$utils.toastLifeTime()
            });
        },
        editPay(pay) {
            this.paymentModel = JSON.parse(JSON.stringify(pay));
            delete this.paymentModel.bank_id;
            this.paymentModel.type_id = pay.type;
            this.getCatalog({query: pay.type.description}, 'paymentTypes', 'description');
            this.$forceUpdate();
        },
        deletePay(pay) {
            this.loading = true;
            return new Promise((resolve, reject) => {
                paymentService
                    .deletePayment(pay.id)
                    .then(response => {
                        this.payments = response.data;
                        const summary = "Atención";
                        const detail = "Pago eliminado correctamente";
                        this.notification(typeNotification.SUCCESS, summary, detail);
                        resolve(response.data);
                        this.loading = false;
                    })
                    .catch(error => {
                        this.processErrorDeleteService(error);
                        reject();
                        this.loading = false;
                    });
            });
        },
        clearForm() {
            this.paymentModel = {
                observation: null,
                date_deposit: null,
                elaboration_date: null,
                check_number: null,
                type: null,
                value_paid: 0,
                datePayment: null,
                nro_doc: null,
                id: null,
                documents: []
            };
        },
        formatMoney(moneyValue) {
            const options = {
                style: "currency",
                currency: "USD"
            };
            return new Intl.NumberFormat(numberFormat.EN_US, options).format(
                moneyValue
            );
        },
        savePayment() {
            this.loading = true;
            if (this.paymentModel.documents != undefined && this.paymentModel.documents.length > fileUploads.FILE_NUMBER_ALLOW) {
                const summary = "Error";
                const detail = "La cantidad de archivos sobrepasa a la permitida.";
                this.notification(typeNotification.ERROR, summary, detail);
                this.loading = false;
                return null;
            }
            if (
                this.paymentModel.check_number == null ||
                this.paymentModel.value_paid < 0 ||
                this.paymentModel.type_id == null ||
                this.paymentModel.date_payment == null
            ) {
                const summary = "Error";
                const detail = "Tienes campos obligatorios sin registrar";
                this.notification(typeNotification.ERROR, summary, detail);
                this.loading = false;
                return null;
            } else {
                return new Promise((resolve, reject) => {
                    this.loading = true;
                    this.paymentModel.type_id = this.paymentModel.type_id.id;
                    this.paymentModel.enrollment_id = this.enrollment.id;
                    if (this.paymentModel.date_deposit != null) {
                        this.paymentModel.date_deposit = moment(
                            this.paymentModel.date_deposit
                        ).format(dateFormats.YYYYMMDD);
                    }
                    if (this.paymentModel.elaboration_date != null) {
                        this.paymentModel.elaboration_date = moment(
                            this.paymentModel.elaboration_date
                        ).format(dateFormats.YYYYMMDD);
                    }
                    this.paymentModel.date_payment = moment(
                        this.paymentModel.date_payment
                    ).format(dateFormats.YYYYMMDD);
                    paymentService
                        .savePayment(this.paymentModel, this.enrollment.id)
                        .then(response => {
                            this.loading = false;
                            this.payments = response.data;
                            this.clearForm();
                            const summary = "Atención";
                            const detail = "Pago grabado correctamente";
                            this.notification(typeNotification.SUCCESS, summary, detail);
                            resolve(response.data);
                            this.submitted = true;
                            this.loading = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            const message = error.response.data;
                            let errorDetail = "";
                            Object.keys(message).forEach(key => {
                                errorDetail +=
                                    errorDetail + (message[key] ?? "")
                                        ? (message[key] ?? "") + "\n "
                                        : "";
                            });
                            const summary = "Error al registrar";
                            this.notification(typeNotification.ERROR, summary, errorDetail);
                            reject();
                            this.loading = false;
                        });
                });
            }
        },
        getCatalog(event, route, field) {
            this.loading = true;
            const originalRoute = route;
            route = route === "paymentTypes" ? `countries/${this.country}/payments/types` : route;
            catalogService
                .getCatalog(event, route, field)
                .then(response => {
                    this[originalRoute] = response;
                    this.loading = false;
                })
                .catch(() => {
                    this[originalRoute] = [];
                    this.loading = false;
                });
        },
        cancel() {
            this.$emit(emits.CANCEL);
        },
        processErrorDeleteService(error) {
            const message = error.response.data;
            let errorDetail = "";
            Object.keys(message).forEach(key => {
                errorDetail +=
                    errorDetail + message[key] ? message[key] + "\n " : "";
            });
            const summary = "Error al eliminar registro";
            this.notification(typeNotification.ERROR, summary, errorDetail);
        }
    }
};
