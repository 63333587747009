<template>
  <div v-if="canAccess('cobranzas_cuentas')">
    <BlockUI :blocked="loading" v-if="loading" :fullScreen="true" class="block-ui">
    </BlockUI>
    <div class="p-field p-fluid p-grid p-col-12 p-jc-between">
      <div
          class="p-field p-fluid p-grid p-col-12 p-sm-6 p-md-3 p-text-center p-jc-center p-ai-center p-dir-col"
      >
        <b>SALDO</b>
        <span>
          {{
            formatMoney(
                (enrollment.plan?.total_value ?? 0) - enrollment.amount_paid
            )
          }}
        </span>
      </div>
      <div
          class="p-field p-fluid p-grid p-col-12 p-sm-6 p-md-3 p-text-center p-jc-center p-ai-center p-dir-col"
      >
        <b>VALOR TOTAL</b>
        <span>
          {{ formatMoney(enrollment.plan?.total_value) }}
        </span>
      </div>
      <div
          class="p-field p-fluid p-grid p-col-12 p-sm-6 p-md-3 p-text-center p-jc-center p-ai-center p-dir-col"
      >
        <b>MONTO COBRADO</b>
        <span>
          {{ formatMoney(enrollment.amount_paid) }}
        </span>
      </div>
      <div
          class="p-field p-fluid p-grid p-col-12 p-sm-6 p-md-3 p-text-center p-jc-center p-ai-center p-dir-col"
      >
        <b>PORCENTAJE RECAUDADO</b><br />{{
          parseFloat(
              (enrollment.amount_paid * 100) / enrollment.plan?.total_value ?? 1
          ).toFixed(2)
        }}%
      </div>
    </div>
    <div class="p-grid">
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <Calendar
              id="date_payment"
              :showIcon="true"
              v-model="paymentModel.date_payment"
              :dateFormat="paymentDateFormat"
              :yearNavigator="true"
              :yearRange="paymentYearRange"
          />
          <label class="p-label" for="date_payment">
            Fecha de pago
            <span class="p-color-required">*</span>
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <InputText
              id="value_paid"
              type="number"
              step="0.01"
              min="0"
              v-model="paymentModel.value_paid"
          />
          <label class="p-label" for="value_paid">
            Monto de pago
            <span class="p-color-required">*</span>
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <AutoComplete
              forceSelection
              id="type"
              :dropdown="true"
              :multiple="false"
              field="description"
              v-model="paymentModel.type_id"
              :suggestions="paymentTypes"
              @itemSelect="selectedPaymentType"
              @complete="getCatalog($event, 'paymentTypes', 'description')"
              class="p-inputtext-sm"
          />
          <label class="p-label" for="type">
            Tipo de pago
            <span class="p-color-required">*</span>
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <InputText
              style="height: 33px;"
              id="check_number"
              type="text"
              v-model="paymentModel.check_number"
              class="p-inputtext-sm"
          />
          <label class="p-label" for="check_number">
            Número de autorización
            <span class="p-color-required">*</span>
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <Calendar
              id="elaboration_date"
              :showIcon="true"
              v-model="paymentModel.elaboration_date"
              dateFormat="dd/mm/yy"
              :base-z-index="20000"
              :yearNavigator="true"
              yearRange="2018:2050"
              class="p-inputtext-sm"
          />
          <label class="p-label" for="elaboration_date">
            Fecha de elaboración
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <Calendar
              id="date_deposit"
              :showIcon="true"
              v-model="paymentModel.date_deposit"
              dateFormat="dd/mm/yy"
              :yearNavigator="true"
              yearRange="2018:2050"
              class="p-inputtext-sm"
          />
          <label class="p-label" for="date_deposit">
            Fecha para depositar
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <InputText
              style="height: 33px;"
              id="nro_doc"
              type="text"
              v-model="paymentModel.nro_doc"
              class="p-inputtext-sm"
          />
          <label class="p-label" for="nro_doc">
            Número de recibo
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12 p-sm-6 p-md-4 p-lg-3">
        <span class="p-float-label">
          <Textarea
              rows="4"
              id="code"
              type="text"
              v-model="paymentModel.observation"
              class="p-inputtext-sm"
          />
          <label class="p-label" for="observation">
            Observación
          </label>
        </span>
      </div>
      <div class="p-field p-fluid p-col-12">
        <label class="p-label" for="paymentFiles">
          <b>Archivos de pago</b>
        </label>
        <div>
        <span class="p-button p-component p-fileupload-choose" style="padding-bottom: 10px">
         <input type="file" id="files" @change="onSelectedFiles($event)"
                name="files" ref="filesInput" multiple style="display: none">
          <span class="p-button-icon p-button-icon-left pi pi-fw pi-plus"></span>
         <label for="files">Seleccionar archivos</label>
       </span>
          <div class="p-datatable p-component p-datatable-responsive-demo p-datatable-sm p-mt-4" v-if="paymentModel.documents.length>0">
            <div class="p-datatable-wrapper">
              <table role="grid">
                <thead class="p-datatable-thead">
                <tr>
                  <th>Nombre</th>
                  <th>Acciones</th>
                </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                <tr v-for="(value, key) in paymentModel.documents" :key="key">
                  <td>{{ value.name || value.name_file }}</td>
                  <td>
                    <Button
                        v-tooltip="'Eliminar'"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-danger p-button-sm"
                        @click="deleteFile(key, value?.id || null)"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="paymentModel.documents.length === 0" class="p-fileupload-empty margin-top-fileupload">
            <div class="flex align-items-center justify-content-center flex-column">
              <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
              <p class="mt-4 mb-0">Seleccione los archivos para subir.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="p-field p-fluid p-grid p-col-12 p-jc-center">
        <div class="p-fluid p-col-fixed">
          <Button
              label="Guardar"
              class="p-button-success"
              @click="savePayment"
          />
        </div>
        <div class="p-fluid p-col-fixed">
          <Button label="Cancelar" class="p-button-danger" @click="cancel" />
        </div>
      </div>
    </div>
    <div class="p-grid p-m-4" v-if="payments.length > 0">
      <div class="p-col-12 p-md-12">
        <DataTable
            :value="payments"
            class="p-datatable-responsive-demo p-datatable-sm"
            responsiveLayout="scroll"
            dataKey="idPay"
        >
          <Column field="date_payment" header="Fecha" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Fecha</span>
              <span class="p-column-value">
                {{ $filters.dateFormat(slotProps.data.date_payment) }}
              </span>
            </template>
          </Column>
          <Column field="nro_doc" header="Nro Recibo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nro Recibo</span>
              <span class="p-column-value">
                {{ slotProps.data.nro_doc ?? "N/A" }}
              </span>
            </template>
          </Column>
          <Column field="type_payment" header="Tipo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tipo</span>
              <span class="p-column-value">
                {{
                  slotProps.data.type_payment == "FEE" ? "CUOTA" : "INSCRIPCIÓN"
                }}
              </span>
            </template>
          </Column>
          <Column field="type.description" header="F. pago" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">F. pago</span>
              <span class="p-column-value">
                {{ slotProps.data?.type?.description }}
              </span>
            </template>
          </Column>
          <Column field="check_number" header="Autorización" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Autorización</span>
              <span class="p-column-value">
                {{ slotProps.data.check_number }}
              </span>
            </template>
          </Column>
          <Column field="value_paid" header="Monto" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Monto</span>
              <span class="p-column-value">
                {{ $filters.moneyFormat(slotProps.data.value_paid) }}
              </span>
            </template>
          </Column>
          <Column field="balance" header="Saldo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Saldo</span>
              <span class="p-column-value">
                {{ $filters.moneyFormat(slotProps.data.balance) }}
              </span>
            </template>
          </Column>
          <Column header="Estado">
            <template #body="slotProps">
              <span class="p-column-title">Estado</span>
              <Tag
                  class="p-mr-2"
                  rounded
                  icon="pi pi-check"
                  severity="success"
                  :value="slotProps.data.status"
                  v-if="slotProps.data.status === approvedKey"
              ></Tag>
              <Tag
                  class="p-mr-2"
                  rounded
                  icon="pi pi-times"
                  severity="danger"
                  :value="slotProps.data.status"
                  v-else-if="slotProps.data.status === denyKey"
              ></Tag>
              <Tag
                  class="p-mr-2"
                  rounded
                  icon="pi pi-info-circle"
                  v-else
                  :value="slotProps.data.status"
              ></Tag>
            </template>
          </Column>
          <Column header="Opciones">
            <template #body="slotProps">
              <span class="p-column-title">Opciones</span>
              <Button
                  title="Eliminar"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                  @click="deletePay(slotProps.data)"
                  v-if="slotProps.data.status === pendingKey"
              />
              <Button
                  title="Editar"
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-warning p-ml-2"
                  @click="editPay(slotProps.data)"
                  v-if="slotProps.data.status === pendingKey"
              />
              <span v-else class="p-d-flex p-justify-center">-</span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>¡ATENCIÓN!</b></h5>
    <div class="card-body">
      <p class="card-title">
        El acceso a esta opción no se encuentra autorizado
      </p>
      <Button
          label="Regresar"
          class="p-button-danger p-mr-2"
          @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>
<script src="./SidebarPayments.js"></script>
<style src="./SidebarPayments.scss" lang="scss" scoped></style>
